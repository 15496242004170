import React from "react"
import { graphql, navigate } from "gatsby"
import SiteLayout from "../components/layout/index"
import Row from "../components/layout/row"
import styled from "@emotion/styled"
import { toLocalDate } from "../helpers"
import { Helmet } from "react-helmet"

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 70%;
  overflow: hidden;
  .alignleft {
    float: left;
    margin: 0 10px 5px 0;
    padding: 5px;
    ${props => props.theme.tablet} {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .alignright {
    float: right;
    margin: 0 0 5px 10px;
    padding: 5px;
    ${props => props.theme.tablet} {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .aligncenter {
    display: block;
    margin: 0 auto;
    height: auto;
    object-fit: cover;
    ${props => props.theme.tablet} {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .alignnone {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  p {
    margin-bottom: 1rem;
  }
  ${props => props.theme.tablet} {
    width: 100%;
    p {
      text-align: justify;
    }
  }
`
const BackwardButton = styled.button`
  border-width: 0px;
  margin-bottom: 0.5rem;
  background-color: transparent;
  font-size: 1.2rem;
  padding: 0;
  line-height: 1;
  font-family: inherit;
  cursor: pointer;
  color: ${props => props.theme.colors.activeLink};
  &:focus {
    outline: none;
  }
`

const DateWrapper = styled.div`
  margin-bottom: 1rem;
`

const Post = props => {
  const goBack = () => {
    navigate(-1)
  }

  const {
    data: {
      wordPress: { post },
    },
  } = props
  const { title, content, date } = post
  return (
    <SiteLayout>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={`${title}, Новость Фонда Защиты Прав Граждан участников строительства в ПК`}
        />
      </Helmet>
      <Row margin="1rem auto">
        <ContentWrapper>
          <Content>
            <BackwardButton onClick={goBack}>&larr; Назад</BackwardButton>
            <DateWrapper>{toLocalDate(date)}</DateWrapper>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Content>
        </ContentWrapper>
      </Row>
    </SiteLayout>
  )
}

export default Post

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wordPress {
      post(id: $id) {
        title
        content
        uri
        date
      }
    }
  }
`
